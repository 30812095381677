@use '../helpers/index.scss' as helpers;
@use 'sass:map';

.Snackbar {
    @include helpers.is(error) {
        .mdc-snackbar__surface {
            background-color: helpers.$error !important;
        }
    }

    @include helpers.is(success) {
        .mdc-snackbar__surface {
            background-color: map.get(helpers.$aw-primary-theme, 800) !important;
        }
    }
}
