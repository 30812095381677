@use '../helpers/index.scss' as helpers;
@use 'sass:map';

.Icon {
    @include helpers.is(success) {
        color: map.get(helpers.$aw-primary-theme, 500);
    }

    @include helpers.is(error) {
        color: helpers.$error;
    }

    @include helpers.is(disabled) {
        color: #999;
    }

    @include helpers.is(xs) {
        --size: 14px;
        font-size: var(--size);
        width: var(--size) !important;
        height: var(--size) !important;
        line-height: 16px !important;
    }
}
