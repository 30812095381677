@use 'sass:math';

.text-gray {
    color: #999 !important;
}

.Content-label {
    font-size: 0.8em;
    text-transform: uppercase;
    color: #666;
    margin-bottom: 2px;
}

.hidden-noprint {
    display: none;

    @media print {
        display: initial;
    }
}

[hidden] {
    display: none !important;
}

// $opacityLevels: (
//   0,
//   5,
//   10,
//   15,
//   20,
//   25,
//   30,
//   35,
//   40,
//   45,
//   50,
//   75,
//   100
// );

// @each $op in $opacityLevels {
//     .opacity-#{$op} {
//       opacity: #{math.div($op, 100)} !important;
//     }
// }
