$aw-dark-theme: (
    primary: #3c4252,
);

$aw-primary-theme: (
    50: #e1f2ef,
    100: #b4ded7,
    200: #85cabd,
    300: #58b4a3,
    400: #3ba490,
    500: #2a947e,
    600: #268772,
    700: #227763,
    800: #1d6755,
    900: #154b3a,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #000000,
        400: #000000,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
    ),
);

$error: #f44336;

$layout-breakpoint-xs: 600px !default;
$layout-breakpoint-sm: 960px !default;
$layout-breakpoint-md: 1280px !default;
$layout-breakpoint-lg: 1920px !default;

$bp-small: '(max-width: 600px)';
$bp-medium: '(max-width: 960px)';
$bp-large: '(max-width: 1280px)';
$bp-xlarge: '(max-width: 1920px)';

:root {
    --color-primary: #2a947e;
    --color-primary-tint: #31aa92;
    --color-primary-shade: #1d705f;
    --color-on-primary: #ffffff;

    --color-secondary: #34495e;
    --color-secondary-tint: #485b6e;
    --color-secondary-shade: #2e4053;
    --color-on-secondary: #ffffff;

    --mat-menu-item-label-text-size: 14px;
    --mat-sidenav-container-width: 250px;
    --mdc-text-button-label-text-color: #000000;
    --mat-list-active-indicator-shape: 0px; // Border radius for list items
    --mat-badge-background-color: var(--color-primary);
    --mdc-icon-button-icon-color: inherit;
    --mat-icon-color: inherit;

    --mdc-filled-text-field-container-color: whitesmoke;
}
