@use '../helpers/index.scss' as helpers;
@use 'sass:map';
@use 'sass:color';

.Calendar {
    ::ng-deep .cal-month-view {
        .cal-days .cal-cell-row {
            position: relative;
        }

        .cal-cell-top {
            min-height: unset;
        }

        .cal-day-cell {
            cursor: pointer;
            // width: 100px;
            // max-height: 300px;

            &.cal-day-selected {
                background-color: map.get(helpers.$aw-primary-theme, 500);
                color: #ffffff;
                position: relative;

                .cal-cell-top {
                    filter: blur(5px);
                }

                &:hover {
                    background-color: color.scale(map.get(helpers.$aw-primary-theme, 500), $lightness: -10.7746478873%);
                    // background-color: darken(map.get(helpers.$aw-primary-theme, 500), 10%);
                }
            }

            &.cal-day-inrange {
                background-color: color.scale(map.get(helpers.$aw-primary-theme, 200), $lightness: 0%);
                // background-color: lighten(map.get(helpers.$aw-primary-theme, 200), 0%);
            }

            .cal-cell-lunch {
                white-space: pre-wrap;
                font-size: 0.8rem;
                color: #666;
                max-height: 100px;
                overflow: hidden;
            }

            &.cal-out-month {
                .cal-cell-price {
                    opacity: 0.3;
                }
            }

            .cal-cell-hoveroverlay {
                width: 100%;
                height: 100%;
                position: absolute;
                display: flex;
                align-items: center;
                justify-content: center;
                animation-duration: 0.3s;

                .mat-icon {
                    font-size: 40px;
                    width: 40px;
                    height: 40px;
                }
            }
        }
    }

    @include helpers.is(hasrange) {
        ::ng-deep .cal-month-view {
            .cal-day-cell {
                &.cal-day-selected {
                    .cal-cell-top {
                        filter: none;
                    }

                    .cal-cell-overlay {
                        display: none;
                    }
                }
            }
        }
    }

    @include helpers.is(rangeselecting) {
        ::ng-deep .cal-month-view {
            .cal-day-cell {
                &:hover {
                    background-color: color.scale(map.get(helpers.$aw-primary-theme, 200), $lightness: 0%);
                    // background-color: lighten(map.get(helpers.$aw-primary-theme, 200), 0%);
                }
            }
        }
    }

    @include helpers.has(week) {
        font-size: 0.8rem;
        color: #999;
        position: absolute;
    }
}
