@use '../helpers/index.scss' as helpers;
@use 'sass:map';

.Tag-list {
    @include helpers.has(tag) {
        color: #fff !important;
    }
}

.Tag-input {
    .ng2-tag-input {
        @include helpers.is(focused) {
            border-color: map.get(helpers.$aw-primary-theme, 500) !important;
        }

        tag {
            &:active,
            &:focus {
                background: map.get(helpers.$aw-primary-theme, 500) !important;
            }

            font-size: 0.8em;
        }
    }

    @include helpers.has(tag) {
        @include helpers.is(deleted) {
            border: 2px solid helpers.$error !important;
        }
    }
}
