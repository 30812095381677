@use '../helpers/index.scss' as helpers;
@use 'sass:map';
@use 'sass:color';
.Sidenav {
    width: 250px !important;
    overflow-y: hidden !important;
}

.Org-list {
    top: 65px;
    position: absolute;
    left: -250px;
    width: 250px;
    // background: lighten(map.get(helpers.$aw-dark-theme, primary), 10%) !important;
    background: color.scale(map.get(helpers.$aw-dark-theme, primary), $lightness: 10%) !important;
    color: #fff;

    @include helpers.has(item) {
        color: #fff;

        &[disabled] {
            color: #aaa;
        }

        @include helpers.has(path) {
            margin: 0;
            line-height: 16px;
            font-size: 12px;

            span {
                &:after {
                    content: ' > ';
                }

                &:last-child {
                    &:after {
                        content: '';
                    }
                }
            }
        }

        @include helpers.has(title) {
            margin: 0;
            line-height: 16px;
        }
    }
}
