@use '../helpers/index.scss' as helpers;

.Card,
.Card.mat-mdc-card {
    --background: #ffffff;
    --color: #000000;

    background-color: var(--background);
    border-radius: 8px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.15) !important;
    display: flex;
    flex-direction: column;
}
