@use '../helpers/index.scss' as helpers;
@use 'sass:map';

.Topbar {
    @include helpers.has(menu) {
        position: absolute;
        width: 220px;
        right: 0;

        @include helpers.has(badge) {
            padding: 2px 10px;
            margin-top: 7px;
            height: 30px;
            line-height: 30px;
            float: right;
            background-color: map.get(helpers.$aw-primary-theme, 500);
            color: #fff;
            border-radius: 100%;
        }
    }
}
