@use '../helpers/index.scss' as helpers;
@use 'sass:map';
.List {
    @include helpers.has(item) {
        &.disabled {
            opacity: 0.3;
            user-select: none;
            /* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version. */
            /* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version. */
            .mat-list-item-content {
                cursor: default;
            }
        }

        @include helpers.has(label) {
            font-size: 1em;
            font-weight: normal;
            margin-bottom: 0;
        }

        @include helpers.has(actions) {
            display: none;
        }

        @include helpers.is(active) {
            border-left: 4px solid map.get(helpers.$aw-primary-theme, 500);
        }

        &:hover {
            .List__item__actions {
                display: flex;
            }
        }
    }
}

.Detail-list {
    @include helpers.has(label) {
        margin-bottom: 0;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 400;
    }

    @include helpers.has(value) {
        p {
            margin-bottom: 0;
        }
    }
}

.Dragdrop-list {
    @include helpers.has(item) {
        .cdk-drag-handle {
            cursor: move;
        }
    }
}

.Selection-list {
    @include helpers.has(item) {
        &.active {
            background-color: map.get(helpers.$aw-primary-theme, 500);
            color: #ffffff;

            .mdc-list-item__primary-text {
                color: #ffffff !important;
            }

            .mat-icon {
                color: #ffffff !important;
            }
        }
    }
}
