@use '../helpers/index.scss' as helpers;
.Loader {
    width: 100%;
    height: 100%;
    position: absolute;
    background: #333;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.3s;
    opacity: 0;
    z-index: -1;

    @include helpers.has(image) {
        width: 150px;
    }
}

app-root:empty + .Loader {
    opacity: 1;
    z-index: 100;
}
