@use '../../../styles/helpers/index.scss' as helpers;

.Dialog {
    @include helpers.has(header) {
        .mat-mdc-icon-button {
            // color: #fff;
        }
    }
    @include helpers.has(title) {
        display: flex !important;
        align-items: center;
        // color: #fff !important;
        line-height: 2rem;
        padding: 0 !important;

        mat-icon {
            margin-right: 5px;
        }
    }

    @include helpers.has(subtitle) {
        color: #fff;
        font-size: 0.8rem !important;
        font-weight: 300 !important;
        line-height: 0.8rem !important;
    }

    @include helpers.has(content) {
        &.mdc-dialog__content.mat-mdc-dialog-content {
            padding: 0;
        }
    }

    @include helpers.has(footer) {
        justify-content: flex-end !important;
        align-items: center;

        @include helpers.is(complex) {
            justify-content: space-between !important;
        }

        @include helpers.has(text) {
            display: flex;
            align-items: center;
            margin: 0;
            font-size: 0.8em;
            color: #666;

            .material-icons {
                font-size: 16px;
                height: 16px;
            }
        }
    }

    @include helpers.has(form) {
        width: 100%;
        box-sizing: border-box;
        padding: 15px;

        .mat-mdc-form-field {
            width: 100%;
        }
    }

    @include helpers.has(inner) {
        width: 100%;
        box-sizing: border-box;
        padding: 15px;
    }

    @include helpers.has(text) {
        font-size: 0.8em;
    }
}
