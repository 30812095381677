@use '@angular/material' as mat;
@use '../_theme-colors.scss' as aware-theme;
@use 'variables' as vars;
@use 'sass:map';

@include mat.all-component-typographies();

$dark: map.merge(
    mat.$m2-blue-grey-palette,
    (
        500: map.get(vars.$aw-dark-theme, primary),
    )
);

$dark-primary: mat.m2-define-palette($dark);
$dark-accent: mat.m2-define-palette(mat.$m2-amber-palette, A200, A100, A400);
$dark-warn: mat.m2-define-palette(mat.$m2-deep-orange-palette);
$dark-theme: mat.m2-define-dark-theme(
    (
        color: (
            primary: $dark-primary,
            accent: $dark-accent,
            warn: $dark-warn,
        ),
    )
);

// @include mat.all-component-themes($app-theme);

html {
    color-scheme: light;
    @include mat.theme(
        (
            color: (
                primary: aware-theme.$primary-palette,
                tertiary: aware-theme.$tertiary-palette,
            ),
            typography: Roboto,
            density: 0,
        )
    );

    // @include mat.all-component-themes(aware-theme.$primary-palette);

    @media (prefers-contrast: more) {
        @include aware-theme.high-contrast-overrides(light);
    }
}

body {
    background: var(--mat-sys-surface);
    color: var(--mat-sys-on-surface);
}

.Sidenav {
    --mat-sidenav-container-background-color: var(--color-secondary) !important;

    @include mat.all-component-colors($dark-theme);
}
